@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(85, 6, 149);
  background: radial-gradient(circle, rgba(85, 6, 149, 0.32816876750700286) 0%, rgba(113, 255, 255, 0.3225665266106442) 92%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.fade-background {
  background: rgb(9, 32, 73);
  background: linear-gradient(180deg, rgba(9, 32, 73, 1) 0%, rgba(20, 20, 157, 1) 63%, rgba(13, 183, 218, 1) 100%);
}

.custom-hover-effect::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  background-color: transparent;
  opacity: 0;
  transition: width 1s ease-out, height 1s ease-out, opacity 1s ease-out;
  border-radius: 50%;
  z-index: -1;
}

.custom-hover-gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgba(255, 37, 215, 0.5) 0%, rgba(160, 160, 241, 0.5) 100%);
  opacity: 0;
  transition: opacity 1s ease-out;
  pointer-events: none;
}

.custom-hover-effect:hover::before {
  width: 200%;
  height: 200%;
  background-color: rgba(160, 160, 241, 0.5);
  opacity: 1;
  transform: translate(-50%, -50%);
}

.custom-hover-effect:hover .custom-hover-gradient {
  opacity: 1;
}

.heading-light {
  background: -webkit-linear-gradient(#500049, #dbd645);
  background-clip: initial;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-light {
  background: -webkit-linear-gradient(#685f67, #000000);
  background-clip: initial;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.heading-dark {
  background: -webkit-linear-gradient(#efda86, #ffffff);
  background-clip: initial;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-dark {
  background: -webkit-linear-gradient(#ffffff, #6e8886);
  background-clip: initial;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}