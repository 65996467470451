@mixin sp-layout {
    @media screen and (max-width: 750px) {
        @content;
    }
}

.star-background {
    display: grid;
    justify-content: center;
    align-items: center;
    background: radial-gradient(ellipse at bottom, #5c7a93 0%, #000000 100%);
    overflow: hidden;
}
@media (min-width: 768px) {
    .star-background {
        display: flex;
    }
}

@function random_range($min, $max) {
    $rand: random();
    $random_range: $min + floor($rand * (($max - $min) + 1));
    @return $random_range;
}

.stars {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    // height: 120%;
    transform: rotate(-45deg);
}

.star {
    $star-count: 50;
    --star-color: #C0C0C0;
    --star-tail-length: 6em;
    --star-tail-height: 2px;
    --star-width: calc(var(--star-tail-length) / 6);
    --fall-duration: 15s;
    --tail-fade-duration: var(--fall-duration);

    position: absolute;
    top: var(--top-offset);
    left: 0;
    width: var(--star-tail-length);
    height: var(--star-tail-height);
    color: #C0C0C0;
    background: linear-gradient(45deg, currentColor, transparent);
    border-radius: 50%;
    filter: drop-shadow(0 0 6px currentColor);
    transform: translate3d(104em, 0, 0);
    animation: fall var(--fall-duration) var(--fall-delay) linear infinite, tail-fade var(--tail-fade-duration) var(--fall-delay) ease-out infinite;

    @include sp-layout {
        // For mobile performance, tail-fade animation will be removed QAQ
        animation: fall var(--fall-duration) var(--fall-delay) linear infinite;
    }

    @for $i from 1 through $star-count {
        &:nth-child(#{$i}) {
            --star-tail-length: #{random_range(500em, 750em) / 100};
            --top-offset: #{random_range(0vh, 10000vh) / 100};
            --fall-duration: #{random_range(6000, 12000s) / 1000};
            --fall-delay: #{random_range(0, 10000s) / 1000};
        }
    }

    &::before,
    &::after {
        position: absolute;
        content: '';
        top: 0;
        left: calc(var(--star-width) / -2);
        width: var(--star-width);
        height: 100%;
        background: linear-gradient(45deg, transparent, currentColor, transparent);
        border-radius: inherit;
        animation: blink 10s linear infinite;
    }

    &::before {
        transform: rotate(45deg);
    }

    &::after {
        transform: rotate(-45deg);
    }
}

@keyframes fall {
    to {
        transform: translate3d(-30em, 0, 0);
    }
}

@keyframes tail-fade {

    0%,
    50% {
        width: var(--star-tail-length);
        opacity: 1;
    }

    70%,
    80% {
        width: 0;
        opacity: 0.4;
    }

    100% {
        width: 0;
        opacity: 0;
    }
}

@keyframes blink {
    50% {
        opacity: 0.6;
    }
}




@mixin transition {
    @for $i from 1 through 19 {
        div:nth-child(#{$i}) {
            @keyframes example-#{$i} {
                0% {
                    transform: translateY(0);
                }

                50% {
                    transform: translateY(4px);
                    opacity: $i / 7;
                }
            }
        }
    }
}

h1 {

    @include transition;

    @for $i from 1 through 19 {
        div:nth-child(#{$i}) {
            display: inline-block;
            opacity: 1;
            text-shadow: 2px 2px 3px #2e575a;
            animation-name: example-#{$i};
            animation-duration: 3s;
            animation-iteration-count: infinite;
            animation-delay:(#{$i*0.2s});
            animation-timing-function: ease-in-out;
            background: -webkit-linear-gradient(#efda86, #ffffff);
            background-clip: initial;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
}